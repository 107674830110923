import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import Footer from "../components/footer"
import NavigationBar from "../components/Navbar"
import { Helmet } from "react-helmet"

class Tags extends React.Component {
  render() {
    const { pageContext, data } = this.props
    const { tag } = pageContext
    const { edges, totalCount } = data.allMarkdownRemark

    const tagHeader = `${tag}`

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{tagHeader}</title>
          <meta name="description" content="Tag Page" />
        </Helmet>
        <NavigationBar />
        <h1 className="mx-auto px-4 mt-10 mb-10 text-2xl xl:text-3xl 2xl:text-4xl leading-10 text-center text-cool-gray-900 sm:leading-none">
          {tagHeader}
        </h1>

        <section>
          <div className="content max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-9 xl:px-12">
            <div className="relative rounded-lg pt-6 mb-6">
              <div className="relative max-w-auto">
                <div className="grid mx-auto my-2 gap-14 sm:gap-10 md:gap-14 sm:grid-cols-2 xl:grid-cols-3">
                  {edges.map(({ node }) => {
                    const path = node.frontmatter.path
                    const title = node.frontmatter.title
                    const description = node.frontmatter.description
                    const date = node.frontmatter.date
                    const featuredimage = node.frontmatter.featuredimage
                    const id = node.id
                    // console.log(node)
                    return (
                      <Link
                        to={path}
                        className="h-full bg-white shadow-lg"
                        key={id}
                      >
                        <div className="flex flex-col bg-white h-full overflow-hidden rounded ">
                          {featuredimage ? (
                            <div className="flex-shrink-0">
                              <img
                                className="object-cover bg-white mt-2 bg-white px-2 w-full h-full"
                                src={require("../../static/" + featuredimage)}
                                alt="Blog Cover"
                              />
                            </div>
                          ) : null}
                          <div className="flex flex-col justify-between flex-1 p-6 bg-white">
                            <div className="flex-1">
                              <a href="#" className="block">
                                <Link
                                  className="mt-2 text-lg md:text-xl 2xl:text-2xl bg-white leading-7 text-cool-gray-900"
                                  to={path}
                                >
                                  {" "}
                                  {title}{" "}
                                </Link>
                                <p className="mt-3 overflow-hidden bg-white line-clamp-3 text-sm 2xl:text-base bg-white text-left text-gray-500">
                                  {description}
                                </p>
                              </a>
                            </div>

                            <div className="text-sm 2xl:text-base bg-white mt-3 text-gray-500">
                              <time dateTime="2020-03-16"> {date}</time>
                            </div>
                          </div>
                        </div>
                      </Link>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <div className="mt-12">
          <Footer />
        </div>
      </div>
    )
  }
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
              path: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: [$tag] }, visibility: { eq: true } }
      }
    ) {
      totalCount
      edges {
        node {
          id
          excerpt
          frontmatter {
            path
            description
            date(formatString: "DD MMMM YYYY")
            author
            title
            featuredimage
            visibility
          }
        }
      }
    }
  }
`
